.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #ebeff3;
  color: white;
}

.login-inf {
  width: 240px;
  height: 450px;
  border-radius: 20px 0px 0px 20px;
  background-color: #222d32;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  box-shadow: 5px 5px 5px rgba(50, 50, 50, 0.514);
}

.title-informativo {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.title-obs {
  font-size: 16px;
  font-weight: bold;
}

.contente-senha {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  cursor: pointer;
}

.informativo {
  font-size: 16px;
  font-weight: bold;
}

.title-senha {
  color: #222d32;
  margin-right: 22px;
  font-weight: bold;
  font-size: 14px;
}

.title-senha:hover {
  color: gray;
  cursor: pointer;
}

.login-right {
  background-color: white;
  border-radius: 0px 40px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 400px;
  height: 500px;
  box-shadow: 5px 5px 5px rgba(50, 50, 50, 0.514);
}

.login-right h1 {
  font-size: 40px;
  margin-bottom: 40px;
  color: #222d32;
}

.login-right h4 {
  font-weight: 300;
  margin-top: 40px;
  font-size: 15px;
  font-weight: 500;
  color: #222d32;
}

.login-loginInputAmbiente {
  display: flex;
  align-items: center;
  color: gray;
  background-color: #f0efef;
  border-radius: 5px;
  padding: 3px;
  width: 90%;
  height: 50px;
  margin-bottom: 14px;
}

.login-loginInputAmbiente svg {
  margin-left: 10px;
  font-size: 25px;
}

.login-loginInputAmbiente input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  color: gray;
  border: none;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.login-loginInputEmail {
  display: flex;
  align-items: center;
  color: gray;
  background-color: #f0efef;
  border-radius: 5px;
  padding: 3px;
  width: 90%;
  height: 50px;
}

.login-loginInputEmail svg {
  margin-left: 10px;
  font-size: 25px;
}

.login-loginInputEmail input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  color: gray;
  border: none;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.login-loginInputPassword {
  display: flex;
  align-items: center;
  color: gray;
  background-color: #f0efef;
  border-radius: 3px;
  padding: 3px;
  width: 90%;
  height: 50px;
  border-radius: 5px;
  margin-top: 14px;
}

.login-loginInputPassword svg {
  margin-left: 10px;
  font-size: 25px;
}

.login-loginInputPassword input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  color: gray;
  border: none;
  font-size: 17px;
  margin-left: 12px;
  margin-right: 10px;
}

.login-right button {
  width: 70%;
  background-color: #222d32;
  color: rgb(255, 255, 255);
  font-weight: 800;
  height: 40px;
  border-radius: 8px;
  font-size: 18px;
  margin-top: 5px;
  border: none;
  outline-width: 0;
  margin-top: 20px;
}

.login-right button:hover {
  background-color: gray;
  color: #eff2f5;
  cursor: pointer;
}

.login-right button:hover {
  background-color: gray;
  color: #000000;
  cursor: pointer;
}

.login-eye {
  align-items: center;
  justify-content: center;
  font-size: 30;
  cursor: pointer;
  margin-right: 10px;
}

.alerta{
  margin-top: 20px;
  color: red;
  font-weight: bold;
}

.logo{
  margin-bottom: 10px;
  width: 300px;
  height: 95px
}

@media screen and (max-width: 900px) {
  .login-inf {
    display: none;
  }

  .container-login {
    width: 100%;
  }

  .login {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mascInpt{
  text-transform: uppercase
}