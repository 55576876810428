
.ContainerGrid {
  width: 100%;
}

.ColunaCheck-user {
  padding: 8px;
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 12px;
  padding-left: 7px;  
  align-items: center;
}

.grid-tab-user{
  width: 100%;
  overflow-y: auto;
  margin-top: 15px;
}

.ContainerBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}

.ContainerPag {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.icone-buscaAdd{
  padding: 2px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: white;
  text-align: center; 
  width: 35px;
  height: 28px;
  color:#000000;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.icone-buscaAdd:hover{
  opacity: 0.8;
  border: 1px solid #000000;
}

.icone-buscaT{
  padding: 2px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: white;
  text-align: center; 
  width: 33px;
  height: 28px;
  color:#000000;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.container-Dropdown-busca{
  border: 1px solid #222d32;
  padding: 10px;
  border-radius: 5px;
}

.icone-buscaT:hover{
  opacity: 0.6;
  border: 1px solid #000000;
}

.paginate h2{
  font-size: 12px;
  white-space: nowrap;
  margin-top: 10px;
  font-weight: normal; 
}

  .InputBusca{
    display: flex;
    width: 100%;
  }

  .Quant h2{
    font-size: 12px;
    white-space: nowrap;
    margin-top: 10px;
    font-weight: normal;
    margin-right: 10px;
  }

  .SemResut h2{
    font-size: 12px;
    white-space: nowrap;
    margin-top: 2px;
    font-weight: normal;

  }

.InlineContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}


table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  transition: all 0.2s ease-in;
  font-size: 12px;
}

.Title {
  border: 1px solid #d6d3d3;
  font-weight: bold;
  padding: 5px;
  font: optional;
 font-size: 13px;
}

.Coluna {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 40px;
}

.Coluna-icone-btn {
  border: 1px solid #d6d3d3;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.icone-add{
font-size: 18px;
margin-top: 2px;
color:rgb(202, 35, 35);
}

.icone-acao1 {
  cursor: pointer;
  font-size: 10px;
  color: rgb(0, 0, 0);
  background: rgb(9, 177, 189);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-top: 8px;
}

.icone-acao2 {
  cursor: pointer;
  font-size: 10px;
  color: rgb(0, 0, 0);
  background: #ffa500;
  padding: 5px;
  border-radius: 3px;
  margin-right: 3px;
  margin-left: 5px;
}


.icone-acao2:hover{
  opacity: .7;
  }

  .icone-acao1:hover{
    opacity: .7;
    }


  td{
    word-break: break-all;
   }
   
#grid-header {
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: rgb(0, 0, 0);
}


.TR-Coluna:hover {
  background-color: #f5f5f5;
}

.user-filtro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
  font-size: 14px;
  color: #455560;
  width: 100%;
}

.input-box-grid {
  margin-bottom: 5px;
  width: calc(100% / 4 - 10px);
}

.input-box-grid span.details {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  color: #455560;
  margin-top: -12px;
}


.dropdown{
  color: #000000;
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}



.ContainerDropdown{
  margin-bottom: 10px;
}

.icone-acao3 {
  cursor: pointer;
  font-size: 10px;
  color: rgb(0, 0, 0);
  background: #df4545;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  margin-left: 3px;
}
.icone-acao3:hover{
  opacity: .7;
  }
  

@media only screen and (max-width: 835px) {
  .icone-acao3{

    margin-top: 10px;
    margin-left: 0px;
  }

  .icone-acao2{
    margin-top: 10px;
  }

  table {
    width: 100%;
  }

  .input-field {    
    width: auto;
    padding: 5px;
    outline: none;
    font-weight: 450;
    display: flex;
  }
  
  .Container {
    width: 100%;
  }

  .abrir-filtro{
    margin-top: 1px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 10px;
   }  

 
  .InputBusca input{
    width: 100px;
  }
}

.icone-acao5-user:hover{
  opacity: 0.7;
}

.icone-acao5-user {
cursor: pointer;
font-size: 10px;
color: rgb(0, 0, 0);
background: #a8a8a8;
padding: 5px;
border-radius: 3px;
margin-right: 3px;
}


.pagination {
  margin: 8px auto;
  display: flex;
  width: 100%;

}

.pagination li {
  list-style-type: none;
}
.pagination > .active > a {
  background-color: #111827;
  border-color: #111827;
  color: #fff;

}

.pagination > li > a {
  padding: 1px 2px;
  outline: none;
  cursor: pointer;
  
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #111827;

  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #111827;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}


.InputBusca input {
  background-color: #fff;
  -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
  -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
  box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
  color: #222d32;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  display: inline-block;
  padding: 6px 6px;
  border-radius: 4px;
  border: 1px solid #92989e;
  -moz-transition: border linear 0.2s;
  -o-transition: border linear 0.2s;
  -webkit-transition: border linear 0.2s;
  transition: border linear 0.2s;
  border-width: 1px;
  height: 28px;
  margin-bottom: 4px;
  width: 190px;
}

.InputBusca input:hover {
  border: 1px solid #222d32;
}

.InputBusca input:focus {
  border: 2px solid #222d32;
}

.btn-grid-incl{
  font-size: 12px;
  text-align: center;
  padding: 4px;
  background-color: #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}


.Coluna-user-btn {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left:10px;
  padding-right:10px;
  width: 180px;
}

.btn-grid-incl:hover{
  color: #ffffff;
  background-color: #868282;
}

.dropdown-icone-busca{
  display: flex;
}

.Coluna-data {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 80px;
  max-width: 80px;
}

.Coluna-descricao{
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 220px;
  max-width: 220px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Coluna-padrao{
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 110px;
  max-width: 110px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Coluna-id{
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
  max-width: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Coluna-btn {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left:10px;
  padding-right:10px;
  min-width: 120px;
}

.btn-grid-incl{
  font-size: 12px;
  text-align: center;
  padding: 4px;
  background-color: #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
}

.btn-grid-incl:hover{
  color: #ffffff;
  background-color: #868282;
}
