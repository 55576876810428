.backgruound-style{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);
  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.modal-alert {
  z-index: 100;
  background: white;
  position: relative;
  margin:20px  auto;
  max-width: 500px;
  border-radius: 8px;
  height: auto;
}


