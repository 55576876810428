.import-div-lab{
    margin-bottom: 5px;
}

.ContainerBodyComple{
    width: 100%;
    display: flex;
    justify-content: space-between; 
}

.dropOpcao-import{
    cursor: pointer;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    padding: 5px;
    border: 1px solid rgb(192, 192, 192);
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: 120px;
    height: 40px;
  }

.dropOpcao-import:hover{
    opacity: 0.8;
}


.Bt-button-cad-imp {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 52px;
    border-radius: 8px;
    padding: 7px;
    margin-top: 20px;
    margin-bottom: 10px;
    }

   
    
    .Bt-button-cad-imp button {
    font-size: 15px;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    border: 1px solid rgb(102, 102, 102);
    border-radius: 5px;
    margin-left: 17px;
    width: 200px;
    cursor: pointer;

    }
    
    .Bt-button-cad-imp button:hover {
    border: 1px solid #aaa7a7;
    }