.table {
  width: 100%;
  height: 300px;

  .tableCell{
    padding: 7px;
  }

  .cellWrapper {
    display: flex;
    align-items: center;
  }

  .TableRow:hover{
    background-color: rgb(236, 236, 236);
    cursor: pointer;
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }
}
