.ColunaCheck-loc {
  padding: 7px;
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 12px;
  padding-left: 7px;  
  align-items: center;
  
}

.Coluna{

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  padding-right: 7px;
  
}

.Coluna-loc {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
   margin-top: 10px;
}
.TR-Coluna-loc:hover {
  background-color: #f5f5f5;
   margin-top: 10px;
}

.Title-loc {
  border: 1px solid #d6d3d3;
  font-weight: bold;
  padding: 5px;
  font: optional;
 font-size: 12px;

}

#header-loc {
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: rgb(0, 0, 0);
 
}


.ContainerBody{
  margin-bottom: 10px;
}
