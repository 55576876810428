  
  .btn-activity{
  font-size: 15px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  width: 100%;
  border: 1px solid rgb(102, 102, 102);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  }
  
  .btn-activity:hover {
  border: 1px solid #aaa7a7;
  opacity: 0.8;
  }

  .icon-ok-btn{
    color: #0adb49;
  }

  .icon-cancelar-btn{
    color: #ff0000;
  }

  
  .characters {
    list-style: none;
    padding-left: 0;
  }
  
  .characters li {
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    margin: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .characters p {
    max-width: none;
    font-weight: bold;
    margin-top: 2px;
  }
  

.icone-resumo{
  margin-top: 2px;
  cursor: pointer;
}

.icone-resumo:hover{
  color: #ff0000;
}

.container-modal-alert{
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 60px;
  display: block;
  height: 320px;
}





.ControleIconAlert{
  font-size: 100px;
  color: #a5dc86;
   margin-bottom: 20px;
}


.ControleIconAlertcancel{
  font-size: 100px;
  color: #f27474;
   margin-bottom: 20px;
}

.info-alert{
  color: #545454;
  font-size: 18px;
  margin-bottom: 10px;
}

.btn-alert{
  margin-top: 20px;
  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  margin: 0 auto;
  cursor: pointer;
}

.btn-alert:hover{
  opacity: 0.8;
}

.btn-alert-lab{
  color: #fff;
}

.div-info-alert{
  margin-bottom: 20px;
}

.btn-flex{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
}

.btn-alert-flex{
  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  align-items: center;
}

.btn-alert-flex-nao{
  background-color: rgb(221, 51, 51);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  align-items: center;
}


.btn-alert-flex:hover{
  opacity: 0.8;
}

.btn-alert-flex-nao:hover{
  opacity: 0.8;
}