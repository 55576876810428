
.ContainerGrid {
  width: 100%;
}

.ContainerPaginate{
  display: flex;
  width: 100%;
  min-width:100px;
  max-width:200px;
}

.ColunaCheck-user {
  padding: 8px;
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 12px;
  padding-left: 7px;  
  align-items: center;
}

.grid-tab-user{
  width: 100%;
  overflow-y: auto;
  margin-top: 15px;
}

.Container {
  width: 100%;
}

.ContainerBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}

.ContainerPag {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.icone-busca{
  padding: 2px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: white;
  text-align: center; 
  width:35px;
  height: 28px;
  color:#000000;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.paginate h2{
  font-size: 12px;
  white-space: nowrap;
  margin-top: 10px;
  font-weight: normal; 
}

.icone-busca:hover{
  opacity: .7;
  border: 1px solid #111827;
  }

  .input-field {    
    width: 280px;
    height: 28px;
    padding: 5px;
    display: flex;
    outline: none;
    font-size: 12px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #ccc;
    transition: all 0.3s ease;
  }

  .input-field:focus {
    border-color: #111827;
    box-shadow: 0 0 0.2em #111827;
    border-width: 1px;
  }   

  .input-field:hover {
    border: 1px solid #111827;
  }   


  .Quant h2{
    font-size: 12px;
    white-space: nowrap;
    margin-top: 10px;
    font-weight: normal;
    margin-right: 10px;
  }

  .SemResut h2{
    font-size: 12px;
    white-space: nowrap;
    margin-top: 2px;
    font-weight: normal;

  }

  .Quante h2{
    font-size: 12px;
    white-space: nowrap;
    margin-top: 16px;
    font-weight: normal;
    margin-right: 10px;
    
  }



.InlineContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}


table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  transition: all 0.2s ease-in;
  font-size: 12px;
}

.Title-user {
  border: 1px solid #d6d3d3;
  font-weight: bold;
  padding: 5px;
  font: optional;
 font-size: 13px;
}

.Coluna-user {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
}

.Coluna-user-id {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
}

.Coluna-user-data {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left: 10px;
  min-width: 120px;
}



.Coluna-icone-user-alt {
  border: 1px solid #d6d3d3;
  width: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.icone-add{
font-size: 18px;
margin-top: 2px;
color:rgb(202, 35, 35);
}

.abrir-filtro{
  margin-top: 14px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.icone-acao1-user {
  cursor: pointer;
  font-size: 10px;
  color: rgb(0, 0, 0);
  margin-left: 0px;
  background: rgb(9, 177, 189);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
}

.icone-acao1-user:hover{
  opacity: .7;
  
  }



.icone-acao2-user:hover{
  opacity: .7;
  }

 

  td{
    word-break: break-all;
   }
   
#header-user {
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  color: rgb(0, 0, 0);
}

.Coluna-user-id{
  padding: 6px;
}

.TR-Coluna-user:hover {
  background-color: #f5f5f5;

}

.user-filtro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
  font-size: 14px;
  color: #455560;
  width: 100%;
}

.input-box-grid {
  margin-bottom: 5px;
  width: calc(100% / 4 - 10px);
}

.input-box-grid span.details {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  color: #455560;
  margin-top: -12px;
}


.dropdown{
  color: #000000;
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}



.ContainerDropdown{
  margin-bottom: 10px;
}

@media only screen and (max-width: 835px) {



  .icone-acao2-user{
    margin-top: 10px;
  }

  table {
    width: 100%;
  }

  .input-field {    
    width: auto;
    padding: 5px;
    outline: none;
    font-weight: 450;
    display: flex;
  }
  
  .Container {
    width: 100%;
  }

  .abrir-filtro{
    margin-top: 1px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 10px;
   }  

   .Coluna-icone-user-alt{
    padding-bottom: 10px;
  }
}

.pagination {
  margin: 8px auto;
  display: flex;
  width: 100%;

}

.pagination li {
  list-style-type: none;
}
.pagination > .active > a {
  background-color: #111827;
  border-color: #111827;
  color: #fff;

}

.pagination > li > a {
  padding: 1px 2px;
  outline: none;
  cursor: pointer;
  
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #111827;

  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #111827;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.btn-grid-incl{
  font-size: 12px;
  text-align: center;
  padding: 4px;
  background-color: #bdbdbd;
  border-radius: 5px;
  cursor: pointer;
}


.Coluna-user-btn {
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  padding-left:10px;
  padding-right:10px;
  width: 180px;
}

.btn-grid-incl:hover{
  color: #ffffff;
  background-color: #868282;
}

.icone-acao10-user {
  cursor: pointer;
  font-size: 10px;
  color: rgb(0, 0, 0);
  background: #a8a8a8;
  padding: 5px;
  border-radius: 3px;
  margin-right: 3px;
  }

  .icone-acao11-user {
    cursor: pointer;
    font-size: 10px;
    color: rgb(0, 0, 0);
    background: rgb(9, 177, 189);
    padding: 5px;
    border-radius: 3px;
    margin-right: 3px;
    }
    
  .icone-acao12-user{
    cursor: pointer;
    font-size: 10px;
    color: rgb(0, 0, 0);
    background: #ffa500;
    padding: 5px;
    border-radius: 3px;
    margin-right: 3px;
  }