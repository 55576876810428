.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.menu-trigger .dropOpcao{
  cursor: pointer;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  padding: 5px;
  border: 1px solid rgb(192, 192, 192);
 display: flex;
 align-items: center;
 border-radius: 4px;
 width: 95px;
 padding-left: 16px;
}

.menu-trigger .dropOpcao:hover{
  border: 1px solid #000000;
  opacity: 0.8;
}

.dropOpcao h1{
  font-size: 12px;
  margin-right:5px;
}

.dropdown-menu{
  position: absolute;
  top: 125px;
  right: 20px;
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 0px 30px;
  width: 200px;
  margin-right:5px;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  border-radius: 4px;
}

.dropdown-menu span{
  font-size: 20px;
  margin-top: 4px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.dropdown-menu ul li:hover a{
  color: #13293D;
  cursor: pointer;
}

.dropdown-menu ul li:hover span{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
  display: flex;
  margin: 10px auto;
  border: 1px solid #92989e;
  padding: 5px;
}

.dropdownItem img{
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a{
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}