.planner-btn{
   width: 100%; 
   padding: 4px;
}

.planner-panel{
    width: 100%; 
    padding-left: 10px;
    padding-right: 10px;
}

.planner-exibicao{
    width: 100%; 
    padding: 15px;
    border: 1px solid rgb(192, 192, 192);


}

.btn-planner{
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    width: 100%;
    border: 1px solid rgb(192, 192, 192);
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 8px;
}

.btn-planner .lab-icone{
    font-size: 16px;
    margin-left: 5px;
    font-weight: bold;
}

.btn-planner .ControleIcon{
    font-size: 16px;
}

.btn-planner:hover{
    opacity: 0.8;
}

.react-tabs__tab {
    list-style: none;
}

.planner-check{
    display: flex;
}

.user-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
    margin-bottom: 4px;
  }
 
  .user-details2 {
    display: flex;
    font-size: 14px;
    color: #455560;
  }


  .input-c input {
    height: 18px;
    width: 18px;
    outline: none;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 2px;
    border: 1px solid rgb(164, 163, 163);
    transition: all 0.3s ease;
    color: #222d32;
  }

  
 .input-c {
    display: flex;
    padding-bottom: 6px;
 
  }
 

  
 .input-box3 span.details {
    display: block;
    font-weight: bold;
    margin-top: 1px;
    margin-left: 8px;
    color: #222d32;
    margin-bottom: 4px;
    font-size: 14px;
  }

   /*----------------------------TAB INI------------------------- */

 .content-planejador {
    background: #fff;
  }
 
  .container-tab {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
 
  .bloc-tabs {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
 
  }
 
  .tabs {
    text-align: center;
    width: 50%;
    height: auto;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
 
  }
 
  .tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
 
  }
 
  .active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
  }
 
  .active-tabs h1 {
    color: #139c31
  }
 
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 2px;
    background: #222d32;
 
  }
 
 
  .content-tabs {
    flex-grow: 1;
 
  }
 
  .content {
    background: white;
    width: 100%;
    display: none;
  }
 
  .active-content {
    display: block;
 
  }
 
  .containertab-1 {
    display: grid;
    width: 100%;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    grid-template-areas: "content1 content2 content3 ";
    grid-gap: 0.1rem;
  }
 
  #content-tab1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
  }
 
  #content-tab2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
 
  }
 
  #content-tab3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    font-size: 14px;
    color: #455560;
    max-height: 560px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
 
 
  .icone-Modal {
    font-size: 20px;
    color: #455560;
    margin-top: 5px;
    margin-left: 5px;
  }
 
 
  #content-tab2 h1 {
    font-size: 14px;
    color: #222d32;
  }
 
  /*----------------------------TAB FIM------------------------- */