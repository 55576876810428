.alin-listContainer{

  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  height: 360px;
    }
    
    .cont-listTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;

      }
    
      .cont-listTitle .listTitle {
        width: 100%;
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
        font-size: 16px;
      }

    