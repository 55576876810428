.sidebar {
  width: 220px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #222d32;
  z-index: 100;
 
  align-items: center;
}

.sidebar .lab-icone {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  margin-top: 3px;
}

.sidebar-brand {
  height: 65px;
  padding: 0rem 0rem 0rem 0.5rem;
  padding-top: 5px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  color: #e4e4e4;
  font-weight: bold;
  margin-bottom: 20px;
  border-radius: 10px;
}

.sidebar-brand span {
  display: inline-block;
  font-size: 18px;
}

.sidebar-menu{
  margin-top: -16px;
}

.sidebar-menu .side {
  height: 35px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
 
}

.sidebar-menu a {
  color: #fff;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  
}

.sidebar-menu a:hover {
  background: #e4e4e4;
  color: #222d32;
}

.sidebar-menu a span:first-child {
  font-size: 18px;
  padding-right: 1rem;
  padding-left: 1rem;
}

#inline {
  display: inline;
}

.Legenda-side {
  color:#fff;
  font-size: 12px;
  background-color:  #403c3f;
  opacity: 0.9;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  margin-left: 112%;
  display: none;
}

.Legenda-side:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: -4px;
  margin-top: 2px;
  background:  #403c3f;
  -webkit-transform: rotate(45deg);
}

/* Dropdown */

.nav__dropdown-content a {
  padding: 16px;
  overflow: hidden;
  max-height: 13px;
  transition: 0.4s ease-in-out;
  font-weight: bold;
  font-size: 13px;

  
}

.nav__dropdown-content {
  display: grid;
  row-gap: 0.1rem;
  padding: 0.2rem 1.5rem 0.2rem 1rem;
  cursor: pointer;
}

.side-dropdown:checked + .nav__dropdown-content {
  display: block;
}

.sidebar i {
  color: #ffffff;
  margin-left: 55px;
}

.sidebar-menu a:hover i {
  color: #222d32;
  -moz-transform: scale(2.2);
  -webkit-transform: scale(5.5);
  transform: scale(1.3);
}

.Legenda-Dropdown {
  margin-bottom: 10px;
  font-size: 10px;
  background: #403c3f;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  margin-left: 100%;
  margin-top: 10px;
  display: none;
}


.ControleIcon:hover{
  color: #403c3f;
}




