.container-principal {
    background-color: #fff;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  
  .container-cad-prod1 {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod2 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod3 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr;
    grid-template-areas: "content1";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod4 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: "content1 content2";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod5 {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod6 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod7 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 3fr 3fr 1.5fr 1.5fr 1.5fr;
    grid-template-areas: "content1 content2 content3 content4 content5 content6";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod8 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: "content1 content2";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod9 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 1fr;
    grid-template-areas: "content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod10 {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 2fr;
    grid-template-areas: "content1 content2 content3 content4 content5 content6 content7";
    grid-gap: 0.5rem;
    margin-bottom: 4px;
  }
  
  .container-cad-prod11 {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 2fr 3fr;
    grid-template-areas: "content1 content2 content3";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  .container-cad-prod12 {
    display: grid;
    width: 100%;
    grid-template-columns: 4fr 2fr 3fr 2fr;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  

  .container-cad-prod13 {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 2fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }
  
  
  .input-flex-ativos {
    display: flex;
  }
  
  .details-garantia-ativos {
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 8px;
  }
  
  
  #content1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content4 {
    grid-area: content4;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content5 {
    grid-area: content5;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content6 {
    grid-area: content6;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  
  #content7 {
    grid-area: content7;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
  }
  /*---------------------------------DESCRIÇÃO DE CADA BLOCO COM NUMERAÇÃO-------------------------------------------*/
  .title-Page .title-text h2 {
    color: #455560;
    font-size: 30px;
    margin-top: 3px;
    font-weight: normal;
  }
  
  .title-Page hr {
    margin-top: 10px;
  }
  
  .title-Page {
    display: flex;
    flex-direction: column;
  }
  
  .title-text {
    display: flex;
    width: 100%;
  }
  
  .size-x {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #455560;
    margin-right: 10px;
  }
  
  .circle {
    background: #455560;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .title-Page .title-text h2 {
    color: #455560;
    font-size: 20px;
  
    font-weight: normal;
  }
  
  .title-Page {
    border-bottom: 1px solid #12344D;
    height: 35px;
    margin-bottom: 10px;
  }
  
  /*----------------------------------------------------------------------------*/
  
  .modal-titulo {
    font-size: 16px;
    margin-top: 2px;
    margin-left: 10px;
    color: #fff;
  }
  
   .container-cad-dash1 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
  
  }
  
  
  .container-cad-dash2 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr;
    grid-template-areas: "content1 content2";
    grid-gap: 0.5rem;
    margin-bottom: 9px;
  }
  
  
  .container-cad-dash3 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr;
    grid-template-areas: "content1";
    grid-gap: 0.5rem;
  
  }
  
  @media only screen and (max-width: 1020px) {
  
    .container-cad-dash1 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4";
      grid-gap: 0rem;
      margin-bottom: 5px;
    }
    
      .container-cad-dash2 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2";
      grid-gap: 0.5rem;
      margin-bottom: 15px;
    }
  
    .container-cad-dash3 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2";
      grid-gap: 1rem;
      margin-bottom: 15px;
    }
  
    .class-checkbox-user {
      padding: 0px;
      margin-left: 0px;  
    }
  
    .container-cad-prod1 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 10px;
      margin-bottom: 10px;
    }
  
    .container-cad-prod2 {
      grid-template-columns: 2fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod4 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2";
        grid-gap: 10px;
    margin-bottom: 10px;
    }
  
    .container-cad-prod5 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 10px;
    margin-bottom: 10px;
    }
  
    .container-cad-prod6 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod7 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4"
        "content5"
        "content6";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod8 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod9 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .title-Page .title-text h2 {
      color: #455560;
      font-size: 14px;
      font-weight: normal;
      margin-top: 5px;
    }
  
    .container-cad-prod10 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4"
        "content5"
        "content6"
        "content7";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod11 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  
    .container-cad-prod12 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4";
        grid-gap: 10px;
        margin-bottom: 10px;
    }

    .container-cad-prod13 {
      grid-template-columns: 1fr;
      grid-template-areas:
        "content1";
        grid-gap: 10px;
        margin-bottom: 10px;
    }
  }